<template>
  <div class="sidebar">
    <vs-sidebar
      fixed
      :reduce="isMobile || isReduced"
      v-model="active"
      open
      background="light"
    >
      <template v-if="!isMobile && !isReduced" #logo>
        <img
          v-if="!isMobile"
          style="width: 200px; height: 95px"
          src="@/assets/logo-simple.png"
        />
      </template>
      <vs-sidebar-item id="home" to="/">
        <template #icon>
          <ion-icon name="home"></ion-icon>
        </template>
        Menu
      </vs-sidebar-item>
      <vs-sidebar-group id="user">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <ion-icon name="people-circle"></ion-icon>
            </template>
            Accueillants
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="userList" to="/users">
          <template #icon>
            <ion-icon name="list"></ion-icon>
          </template>
          Liste des accueillants
        </vs-sidebar-item>
        <vs-sidebar-item
          v-if="user.habilitation != 'volunteer'"
          id="userAdd"
          to="/user/new"
        >
          <template #icon>
            <ion-icon name="person-add"></ion-icon>
          </template>
          Ajouter un accueillant
        </vs-sidebar-item>
      </vs-sidebar-group>
      <vs-sidebar-group id="ben">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <ion-icon name="people"></ion-icon>
            </template>
            Accueillis
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="benList" to="/recipients">
          <template #icon>
            <ion-icon name="list"></ion-icon>
          </template>
          Liste des accueillis
        </vs-sidebar-item>
        <vs-sidebar-item id="benAdd" to="/recipient/new">
          <template #icon>
            <ion-icon name="person-add"></ion-icon>
          </template>
          Ajouter un accueilli
        </vs-sidebar-item>
      </vs-sidebar-group>

      <vs-sidebar-group id="hotel">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <ion-icon name="business"></ion-icon>
            </template>
            Hotels
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="roomCalendar" to="/room-calendar">
          <template #icon>
            <ion-icon name="calendar"></ion-icon>
          </template>
          Planning des chambres
        </vs-sidebar-item>
        <vs-sidebar-item id="housings" to="/housings">
          <template #icon>
            <ion-icon name="eye"></ion-icon>
          </template>
          Liste des hébergements
        </vs-sidebar-item>
        <vs-sidebar-item id="hotelList" to="/hotels">
          <template #icon>
            <ion-icon name="list"></ion-icon>
          </template>
          Liste des hotels
        </vs-sidebar-item>
        <vs-sidebar-item
          v-if="hasRights('hotel_management') > 0"
          id="hotelAdd"
          to="/hotel/new"
        >
          <template #icon>
            <ion-icon name="add"></ion-icon>
          </template>
          Ajouter un hotel
        </vs-sidebar-item>
      </vs-sidebar-group>
      <vs-sidebar-group id="organism">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <ion-icon name="call"></ion-icon>
            </template>
            Organismes et contacts
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="organismList" to="/organisms">
          <template #icon>
            <ion-icon name="list"></ion-icon>
          </template>
          Liste des organismes
        </vs-sidebar-item>
        <vs-sidebar-item id="organismAdd" to="/organism/new">
          <template #icon>
            <ion-icon name="add"></ion-icon>
          </template>
          Ajouter un organisme </vs-sidebar-item
        ><vs-sidebar-item id="contactList" to="/contacts">
          <template #icon>
            <ion-icon name="people"></ion-icon>
          </template>
          Liste des contacts </vs-sidebar-item
        ><vs-sidebar-item id="contactAdd" to="/contact/new">
          <template #icon>
            <ion-icon name="person-add"></ion-icon>
          </template>
          Ajouter un contact
        </vs-sidebar-item>
      </vs-sidebar-group>
      <vs-sidebar-item id="statistics" to="/statistics">
        <template #icon>
          <ion-icon name="bar-chart"></ion-icon>
        </template>
        Statistiques et rapports
      </vs-sidebar-item>
      <vs-sidebar-item id="attachments" to="/attachments">
        <template #icon>
          <ion-icon name="documents"></ion-icon>
        </template>
        Documents de l'antenne
      </vs-sidebar-item>
      <vs-sidebar-group id="gestion">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <ion-icon name="build"></ion-icon>
            </template>
            Gestion
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="helpGestion" to="/helps">
          <template #icon>
            <ion-icon name="pricetags"></ion-icon>
          </template>
          Gestion des aides
        </vs-sidebar-item>
        <vs-sidebar-item id="statistics-admin" to="/statistics-admin">
          <template #icon>
            <ion-icon name="bar-chart"></ion-icon>
          </template>
          Statistiques administrateur
        </vs-sidebar-item>
        <vs-sidebar-item
          v-if="hasRights('antennas_management')"
          id="antennaGestion"
          to="/antennas"
        >
          <template #icon>
            <ion-icon name="location"></ion-icon>
          </template>
          Gestion des antennes
        </vs-sidebar-item>
        <vs-sidebar-item
          v-if="hasRights('ref_management')"
          id="refGestion"
          to="/choices"
        >
          <template #icon>
            <ion-icon name="list-circle"></ion-icon>
          </template>
          Gestion des listes de référence
        </vs-sidebar-item>
      </vs-sidebar-group>
      <template v-if="!isMobile" #footer>
        <div
          class="d-flex flex-row-reverse"
          :class="isReduced ? 'clickiconreduced' : 'clickicon'"
        >
          <ion-icon
            v-on:click="reduce"
            class="p-2"
            style="font-size: 2rem; cursor: pointer"
            :name="isReduced ? 'caret-forward-outline' : 'caret-back-outline'"
          ></ion-icon>
        </div>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { hasRights } from "../../services/utils";
export default {
  name: "sidebar",
  props: {
    isReduced: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: "home",
    };
  },
  computed: {
    ...mapState(["user"]),
    isMobile() {
      return screen.width <= 600;
    },
  },
  methods: {
    hasRights(...rights) {
      return hasRights(this.user, ...rights);
    },
    goToPage(path) {
      this.$router.push(path);
    },
    reduce() {
      this.$emit("reduce");
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  height: 100vh;
  .vs-sidebar-content {
    z-index: 2 !important;
  }
  .clickicon {
    width: 260px;
  }
  .clickiconreduced {
    width: 50px;
  }
}
</style>