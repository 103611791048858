<template>
  <div class="home">
    <div class="d-flex">
      <antenna-fiche />
      <siege-fiche />
    </div>
    <div v-if="true" id="dashboard">
      <div class="d-flex">
        <housing-dashboard />
        <domiciliation-dashboard />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AntennaFiche from "../components/antenna/AntennaFiche.vue";
import SiegeFiche from "../components/antenna/SiegeFiche.vue";
import HousingDashboard from "../components/dashboard/HousingDashboard.vue";
import DomiciliationDashboard from "../components/dashboard/DomiciliationDashboard.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    AntennaFiche,
    SiegeFiche,
    HousingDashboard,
    DomiciliationDashboard
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.antenna-fiche {
  flex: 2;
}
.siege-fiche {
  flex: 1;
}
.alert-dashboard {
  flex: 1;
}
.activity-dashboard {
  flex: 1;
}
.data-quality-dashboard {
  flex: 1;
}
.housing-dashboard {
  flex: 1;
}
.domiciliation-dashboard {
  flex: 1;
}
</style>