<template>
  <div class="domiciliation-dashboard">
    <div class="domiciliation-list pl-2 pt-4 pr-4">
      <widget headerText="Domiciliations arrivant à échéance">
        <rotate-square2 v-if="isLoading"></rotate-square2>
        <b-table
          v-else
          striped
          hover
          :items="domiciliations"
          :fields="fields"
          @row-clicked="handleRowClick"
          :tbody-tr-class="domiciliationClass"
          :sort-by="filter.sortBy"
          :sort-desc="filter.sortDesc"
          @sort-changed="sortChanged"
        ></b-table>
        <div class="pagination d-flex justify-content-end">
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >Précédent</span
                >
              </li>
              <li class="page-item" v-if="page > 0">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page -= 1;
                    }
                  "
                  >{{ page }}</span
                >
              </li>
              <li class="page-item active">
                <span class="page-link" href="#"
                  >{{ page + 1 }} <span class="sr-only">(current)</span></span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >{{ page + 2 }}</span
                >
              </li>
              <li class="page-item" v-if="hasMorePages">
                <span
                  class="page-link"
                  href="#"
                  v-on:click="
                    () => {
                      page += 1;
                    }
                  "
                  >Suivant</span
                >
              </li>
            </ul>
          </nav>
        </div>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
import { RotateSquare2 } from "vue-loading-spinner";
export default {
  name: "domiciliation-dashboard",
  components: { Widget, RotateSquare2 },
  props: {
    user: {
      required: false,
      type: Object,
    },
    antenna: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      domiciliations: [],
      users: [],
      page: 0,
      pages: 1,
      per_page: 10,
      filter: {
        sortBy: "end_date",
        sortDesc: false,
        user_id: null,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["user", "antennas"]),
    hasMorePages() {
      return this.page + 1 < this.pages;
    },
    fields() {
      return [
        {
          key: "recipient.surname",
          label: "Prénom",
          sortable: true,
        },
        {   
          key: "recipient.name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "user",
          label: "Référent MRS",
          formatter: (val) =>
            val
              ? val.surname + " " + (val.name ? val.name.toUpperCase() : "")
              : "",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Du",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
        {
          key: "end_date",
          label: "Au",
          sortable: true,
          formatter: (val) =>
            val ? this.$moment(val).format("DD/MM/YYYY") : "",
        },
      ];
    },
  },
  mounted() {
    this.refreshDomiciliationList();
    this.refreshLists();
  },
  watch: {
    page() {
      this.refreshDomiciliationList();
    },
    user() {
      this.refreshDomiciliationList();
    },
  },
  methods: {
    sortChanged(e) {
      this.filter.sortBy = e.sortBy;
      this.filter.sortDesc = e.sortDesc;
      this.refreshDomiciliationList();
    },
    domiciliationClass(domiciliation, type) {
      if (type == "row") {
        if (
          domiciliation.next
        ) {
          return "table-success";
        } else {
            if (this.$moment(domiciliation.end_date) - this.$moment() < 0) {
                return "table-danger"
            } else {
                return "table-warning"
            }
        }
      } else {
        return;
      }
    },
    refreshLists() {
      if (
        this.user.habilitation == "volunteer" ||
        this.user.habilitation == "antenna_admin"
      ) {
        axios
          .get(`/api/web/users/antenna/${this.user.antenna_id}`)
          .then((res) => {
            this.users = res.data.data;
          });
      } else {
        axios.get(`/api/web/users`).then((res) => {
          this.users = res.data.data;
        });
      }
    },
    refreshDomiciliationList() {
      this.isLoading = true;
      if (this.user) {
        this.filter.user_id = this.user._id;
      }
      axios
        .get("/api/web/domiciliation/dashboard", {
          params: {
            filter: this.filter,
            page: this.page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.pages = res.data.pagination.last_page + 1;
          this.domiciliations = res.data.data.map((h) => {
            return h;
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },  
    handleRowClick(item) {
      this.$router.push(`/recipient/${item.recipient_id}#dom`);
    },
  },
};
</script>