<template>
  <div class="hotels">
    <div class="hotel-list p-4">
      <widget headerText="Liste des Hotels">
        <b-table
          striped
          hover
          :items="hotels"
          :fields="fields"
          @row-clicked="handleRowClick"
        ></b-table>
      </widget>
    </div>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "hotels",
  components: { Widget },
  data() {
    return {
      hotels: [],
    };
  },
  props: {
    searchInput: {
      type: String,
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
    fields() {
      return [
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "address",
          label: "Code postal",
          sortable: true,
          formatter: (val) => val.postal_code,
        },
        {
          key: "archived",
          label: "Archivé",
          sortable: true,
          formatter: (val) => (val ? "Oui" : ""),
        },
        {
          key: "rooms",
          label: "Chambres",
          formatter: (val) => val.length,
        },
        {
          key: "freeRooms",
          label: "Chambres libres",
        },
      ];
    },
  },
  mounted() {
    this.refreshHotelList();
  },
  watch: {
    searchInput() {
      this.refreshHotelList();
    },
  },
  methods: {
    refreshHotelList() {
      axios
        .get("/api/web/hotels", { params: { search: this.searchInput } })
        .then((response) => {
          var hotels = response.data.data;
          hotels.map((h) => {
            h.freeRooms = h.rooms.filter((room) => room.free).length;
          });
          this.hotels = hotels;
        });
    },
    handleRowClick(item) {
      this.$router.push(`/hotel/${item._id}`);
    },
  },
};
</script>