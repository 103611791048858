<template>
  <div class="statistics-amdin">
    <div class="new-statistic p-4">
      <form novalidate class="md-layout" @submit.prevent="generateStatistics">
        <widget headerText="Générer un rapport">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-30">
              <md-field :class="getValidationClass('type')">
                <label for="statistic_type">Type de rapport</label>
                <md-select
                  name="statistic_type"
                  id="statistic_type"
                  v-model="selectedStatisticType"
                >
                  <md-option
                    v-for="(s, i) in statisticTypes"
                    :key="i"
                    :value="s.type"
                  >
                    {{ s.label }}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.currentStatistic.type.required"
                  >Le type de statistique est requis</span
                >
              </md-field>
            </div>
          </div>
          <md-subheader v-if="selectedStatisticType">Paramètres</md-subheader>
          <div class="md-layout md-gutter">
            <div
              v-if="hasParam('antenna_id')"
              class="md-layout-item md-small-size-100"
            >
              <md-field>
                <label for="statistic_type">Antenne</label>
                <md-select
                  name="statistic_type"
                  id="statistic_type"
                  v-model="currentStatistic.params.antenna_id"
                >
                  <md-option
                    v-for="(s, i) in antennas"
                    :key="i"
                    :value="s._id"
                    :disabled="isAntennaDisabled(s)"
                  >
                    {{ s.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div
              v-if="hasParam('start_date')"
              class="md-layout-item d-flex align-items-center"
            >
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Du :
                  <vuestic-date-picker
                    v-model="currentStatistic.params.start_date"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
            <div
              v-if="hasParam('end_date')"
              class="md-layout-item d-flex align-items-center"
            >
              <md-field class="no-underline">
                <div class="d-flex align-items-center justify-content-center">
                  Au (non inclus) :
                  <vuestic-date-picker
                    v-model="currentStatistic.params.end_date"
                    :config="dateConfig"
                  />
                </div>
              </md-field>
            </div>
          </div>
          <button v-if="!isLoading" type="submit" class="btn btn-primary">
            Générer le rapport
          </button>
        </widget>
      </form>
    </div>
    <rotate-square2 v-if="isLoading"></rotate-square2>
  </div>
</template>

<script>
import Widget from "../../components/widgets/Widget";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import VuesticDatePicker from "../../components/widgets/VuesticDatePicker";
import { French } from "flatpickr/dist/l10n/fr.js";
import { RotateSquare2 } from "vue-loading-spinner";
export default {
  name: "statistics-admin",
  components: { Widget, VuesticDatePicker, RotateSquare2 },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      dateConfig: {
        locale: French,
        dateFormat: "Y-m-d",
        altInput: true,
      },
      statisticTypes: [],
      selectedStatisticType: null,
      statisticParams: {},
      currentStatistic: {},
      excludedStatistics: [
        "alt_statistics",
        "recipient_statistics",
        "user_statistics",
        "domiciliation_statistics",
        "formation_statistics",
        "application_statistics",
      ],
    };
  },
  validations() {
    return { currentStatistic: { type: required } };
  },
  watch: {
    selectedStatisticType() {
      this.getDefaultStatisticOfType(this.selectedStatisticType);
    },
  },
  computed: {
    ...mapState(["user", "antennas"]),
  },
  mounted() {
    this.getStatisticTypes();
  },
  methods: {
    hasParam(param) {
      return (
        this.currentStatistic.requested_params &&
        this.currentStatistic.requested_params.indexOf(param) >= 0
      );
    },
    validateParams() {
      return this.selectedStatisticType != null;
    },
    isAntennaDisabled(antenna) {
      return (
        this.user.rights.indexOf("statistics_absolute") < 0 &&
        this.user.antenna_id != antenna._id
      );
    },
    getValidationClass(fieldName) {
      var field;
      if (fieldName.indexOf(".") >= 0) {
        field =
          this.$v.currentStatistic[fieldName.split(".")[0]][
            fieldName.split(".")[1]
          ];
      } else {
        field = this.$v.currentStatistic[fieldName];
      }

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    getStatisticTypes() {
      axios.get(`/api/web/statistic_types`).then((res) => {
        this.statisticTypes = res.data.data.filter((item) =>
          this.excludedStatistics.includes(item.type)
        );
      });
    },
    getDefaultStatisticOfType(type) {
      axios.get(`/api/web/statistic_default/${type}`).then((res) => {
        this.currentStatistic = this._.cloneDeep(res.data.data);
        if (this.hasParam("antenna_id")) {
          this.currentStatistic.params.antenna_id = this.user.antenna_id;
        }
      });
    },
    generateStatistics() {
      if (this.validateParams()) {
        this.$toasted
          .show("Rapport en cours de calcul. Veuillez patienter")
          .goAway(3000);
        this.isLoading = true;
        axios
          .put(
            `/api/web/statistics`,
            { statistic: this.currentStatistic },
            { responseType: "blob" }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            this.fileLength = parseInt(res.headers["content-length"]);
            if (this.fileLength < 1000) {
              this.fileLength += " octets";
            } else if (this.fileLength < 1000000) {
              this.fileLength = Math.round(this.fileLength / 1000) + " Ko";
            } else {
              this.fileLength = Math.round(this.fileLength / 1000) + " Mo";
            }
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", res.headers.filename);
            document.body.appendChild(link);
            link.click();
            this.isDownloading = false;
            this.$toasted
              .show("Le rapport est prêt et en cours de téléchargement")
              .goAway(1500);
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response.data.message == "STAT_DID_NOT_PRODUCE_FILE") {
              this.$toasted.error("Aucun fichier n'a été produit").goAway(5000);
            } else if (
              err.response.data.message == "OBJECT_CANNOT_BE_VALIDATED"
            ) {
              this.$toasted
                .error("Les paramètres ont été mal rentrés")
                .goAway(5000);
            } else if (
              err.response.data.message == "SOMETHING_WRONG_WITH_STATS"
            ) {
              this.$toasted.error("Erreur inconnue").goAway(5000);
            } else {
              this.$toasted
                .error("Aucun fichier n'a été produit. Erreur de données")
                .goAway(5000);
            }
            this.isLoading = false;
          });
      } else {
        this.$toasted
          .show("Les paramètres n'ont pas été correctement rentrés")
          .goAway(3000);
      }
    },
  },
};
</script>

<style>
</style>