import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login/Login.vue"
import Users from "../views/users/Users.vue"
import User from "../views/users/User.vue"
import Hotel from "../views/hotel/Hotel.vue"
import Hotels from "../views/hotel/Hotels.vue"
import RoomCalendar from "../views/housing/RoomCalendar.vue"
import Organism from "../views/organism/Organism.vue"
import Organisms from "../views/organism/Organisms.vue"
import Housings from "../views/housing/Housings.vue"
import Contact from "../views/contact/Contact.vue"
import Contacts from "../views/contact/Contacts.vue"
import NewRecipient from "../views/recipient/NewRecipient.vue"
import Recipients from "../views/recipient/Recipients.vue"
import Recipient from "../views/recipient/Recipient.vue"
import Statistics from "../views/statistics/Statistics.vue"
import StatisticsAdmin from "../views/statistics/StatisticsAdmin.vue"
import Choices from "../views/choices/Choices.vue"
import Helps from "../views/itemList/Helps.vue"
import Help from "../views/itemList/Help.vue"
import AntennaManagement from "../views/antenna/AntennaManagement.vue"
import Attachments from "../views/attachments/Attachments.vue"
import Alerts from "../views/alert/Alerts.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/user/:user_id',
    name: 'User',
    component: User,
  },
  {
    path: '/antennas',
    name: 'antenna-management',
    component: AntennaManagement,
  },
  {
    path: '/recipients',
    name: "recipients",
    component: Recipients
  },
  {
    path: '/recipient/new',
    name: "new-recipient",
    component: NewRecipient
  },
  {
    path: '/recipient/:recipient_id',
    name: "recipient",
    component: Recipient
  },
  {
    path: '/helps',
    name: "helps",
    component: Helps
  },
  {
    path: '/help/:help_id',
    name: "help",
    component: Help
  },
  {
    path: '/choices',
    name: "choices",
    component: Choices
  },
  {
    path: '/hotel/:hotel_id',
    name: "hotel",
    component: Hotel
  },
  {
    path: '/room-calendar',
    name: "room-calendar",
    component: RoomCalendar
  },
  {
    path: '/hotels',
    name: "hotels",
    component: Hotels
  },
  {
    path: '/organism/:organism_id',
    name: "organism",
    component: Organism
  },
  {
    path: '/organisms',
    name: "organisms",
    component: Organisms
  },
  {
    path: '/housings',
    name: "housings",
    component: Housings
  },
  {
    path: '/contact/:contact_id',
    name: "contact",
    component: Contact
  },
  {
    path: '/contacts',
    name: "contacts",
    component: Contacts
  },
  {
    path: "/statistics",
    name: "statistics",
    component: Statistics
  },
  {
    path: "/statistics-admin",
    name: "statistics-admin",
    component: StatisticsAdmin
  },
  {
    path: "/attachments",
    name: "attachments",
    component: Attachments
  },
  {
    path: "/alerts",
    name: "alerts",
    component: Alerts
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
