
export const workTabs = [{
    title: "Emplois",
    click: true,
    id: "jobs",
  },
  {
    title: "Formations",
    click: false,
    id: "formations",
  }]