<template>
  <div class="recipient-work">
    <div class="d-flex justify-content-center">
      <tabs id="work" :tabs="tabs" @clickTab="selectTab"> </tabs>
    </div>
    <recipient-formation
      v-if="selectedTabId=='formations'"
      :recipient_id="recipient_id"
      @openFormationModal="openFormationModal"
      @openQualificationModal="openQualificationModal"
    />
    <recipient-job
      v-else
      :recipient_id="recipient_id"
      @openJobModal="openJobModal"
      @openExperienceModal="openExperienceModal"
    />
    <comments
      :recipient_id="recipient_id"
      field="work"
      @openCommentModal="openCommentModal"
    />
  </div>
</template>

<script>
import RecipientFormation from "../work/RecipientFormation.vue";
import RecipientJob from "../work/RecipientJob.vue";
import Comments from "../comment/Comments.vue";
import Tabs from "../../components/tabs/Tabs.vue";
import {workTabs} from './workTabs'
export default {
  name: "recipient-work",
  components: { RecipientFormation, Comments, RecipientJob, Tabs },
  props: {
    recipient_id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      tabs: workTabs,
      selectedTabId: "jobs",
      formation: true,
    };
  },
  methods: {
    selectTab(tab) {
      this.tabs = this.tabs.map((t) => {
        t.click = t.id == tab.id;
        return t;
      });
      this.selectedTabId = tab.id;
    },
    goWork() {
      this.formation = false;
    },
    goFormation() {
      this.formation = true;
    },
    openCommentModal(payload) {
      this.$emit("openCommentModal", payload);
    },
    openFormationModal(payload) {
      this.$emit("openFormationModal", payload);
    },
    openJobModal(payload) {
      this.$emit("openJobModal", payload);
    },
    openQualificationModal(payload) {
      this.$emit("openQualificationModal", payload);
    },
    openExperienceModal(payload) {
      this.$emit("openExperienceModal", payload);
    },
  },
};
</script>

<style>
</style>