
export const housingTabs = [{
    title: "Hébergements MRS",
    click: true,
    id: "Hébergements MRS",
  },
  {
    title: "Hébergements d'urgence",
    click: false,
    id: "Hébergements d'urgence",
  },
  {
    title: "Hébergements hors MRS",
    click: false,
    id: "Hébergements hors MRS",
  }]